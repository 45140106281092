define("discourse/plugins/discourse-envato/discourse/templates/connectors/category-custom-settings/discourse-envato", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field">
    <label>
      Web Crawler Title
      {{text-field value=category.custom_fields.envato_seo_title}}
    </label>
  </section>
  
  <section class="field">
    <label>
      Web Crawler Description
      {{text-field value=category.custom_fields.envato_seo_description}}
    </label>
  </section>
  */
  {
    "id": "LtFDgKfr",
    "block": "[[[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    Web Crawler Title\\n    \"],[1,[28,[35,0],null,[[\"value\"],[[30,0,[\"category\",\"custom_fields\",\"envato_seo_title\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    Web Crawler Description\\n    \"],[1,[28,[35,0],null,[[\"value\"],[[30,0,[\"category\",\"custom_fields\",\"envato_seo_description\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `category` property path was used in the `discourse/plugins/discourse-envato/discourse/templates/connectors/category-custom-settings/discourse-envato.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `category` property path was used in the `discourse/plugins/discourse-envato/discourse/templates/connectors/category-custom-settings/discourse-envato.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"text-field\"]]",
    "moduleName": "discourse/plugins/discourse-envato/discourse/templates/connectors/category-custom-settings/discourse-envato.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});